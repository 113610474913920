import { httpClient } from './httpClient'
import { Journey, LegDetailed } from '@/models/Journey'
import { Fares, gatewayEncodeFares } from '@/helpers/fares'
export interface SearchParams {
  departureDate: string;
  returnDate?: string;
  fares: Fares;
  originId: number;
  destinationId: number;
  promoCode?: string;
  isOutbound: boolean;
  oneWay: boolean;
  wheelchair?: boolean;
  wheelchairPassengerFareClass?: string;
}

const route = '/journeys'

const prepareParams = ({
  departureDate,
  returnDate,
  fares,
  originId,
  destinationId,
  promoCode,
  isOutbound,
  oneWay,
  wheelchair,
  wheelchairPassengerFareClass
}: SearchParams) => ({
  DepartureDate: isOutbound ? departureDate : returnDate,
  FareClasses: gatewayEncodeFares(fares),
  OriginBusStopId: isOutbound ? originId : destinationId,
  DestinationBusStopId: isOutbound ? destinationId : originId,
  PromoCode: promoCode,
  Currency: 'CURRENCY.EUR',
  IsOutbound: isOutbound,
  IsPartOfRoundtrip: !oneWay,
  IsWheelchair: wheelchair,
  WheelchairPassengerFareClass: wheelchairPassengerFareClass
})

const getJourneys = (params: SearchParams): Promise<Journey[]> =>
  httpClient({
    method: 'GET',
    url: `${route}/search`,
    params: prepareParams(params),
    timeout: 50000
  })
    .then(response => response.data.Journeys)

const getJourneyDetails = (journeyId: string) =>
  httpClient
    .get<{ Legs: LegDetailed[]}>(`${route}/${journeyId}`)
    .then(response => response.data.Legs)

export { getJourneys, getJourneyDetails }
