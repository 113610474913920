
import useVuelidate from '@vuelidate/core'
import { computed, defineComponent, toRef } from 'vue'
import { email, helpers } from '@vuelidate/validators'
import Input from '@/components/Input.vue'
import { fareStrings } from '@/models/FareClass'

export default defineComponent({
  emits: ['update:passenger'],
  components: { 'v-input': Input },
  props: {
    index: {
      required: true,
      type: Number
    },
    passenger: {
      required: true,
      type: Object
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  setup (props) {
    const rules = {
      PassengerFirstName: {},
      PassengerLastName: {},
      Email: { email: helpers.withMessage('Please enter a valid Email Address', email) }
    }

    const fareClassString = fareStrings[props.passenger.FareClass]

    const passengerTitle = computed(() =>
      ({
        'Free Travel Seat': 'Seat only reservation (Free travel scheme)',
        'Reserved Seat': 'Seat only reservation (Valid ticket required)'
      } as Record<string, string>)[fareClassString] || fareClassString
    )

    return {
      passengerTitle,
      v$: useVuelidate(rules, toRef(props, 'passenger'))
    }
  }
})
