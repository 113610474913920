
import { defineComponent, PropType, computed, inject, ComputedRef, ref } from 'vue'
import Modal from '@/components/Modal.vue'
import Spinner from '@/components/Spinner.vue'
import CheckLineIcon from '@/components/vectors/CheckLineIcon.vue'
import useTrip from '@/composables/useTrip'
import { PassengerGroup, PassengerGroupType } from '@/models/Passenger'
import { formatPrice, resolveDiscountMessage, calculateJourneyDiscountStatus } from '@/helpers'
import { Addon, Addons } from '@/models/Addon'

export default defineComponent({
  components: { Modal, Spinner, CheckLineIcon },
  props: {
    passengerGroup: {
      type: Object as PropType<PassengerGroup>,
      required: true
    },
    promoCode: {
      type: String
    }
  },
  setup (props) {
    const groupAddons = ref()
    const addons = inject<ComputedRef<Addons>>('addons')
    const { trip } = useTrip()

    if (props.passengerGroup.groupType === 'Outward') {
      groupAddons.value = addons?.value?.Addons?.Outbound
    } else if (props.passengerGroup.groupType === 'Return') {
      groupAddons.value = addons?.value?.Addons?.Inbound
    }

    const addonsSubtotal = computed((): number =>
      groupAddons.value?.reduce((acc: number, addon: Addon) =>
        acc + addon.Quantity * addon.UnitPrice, 0) ?? 0)

    const passengersSubtotal = computed((): number =>
      props.passengerGroup.passengers.reduce((a: number, b: PassengerGroupType): number =>
        a + b.price * b.quantity, 0))

    const subtotal = computed((): number =>
      passengersSubtotal.value + addonsSubtotal.value)

    const promoCodeMessage = computed(() => {
      let Outward
      let Return
      if (!trip.outboundJourney?.journey.Legs) {
        Outward = ''
      } else {
        Outward = calculateJourneyDiscountStatus(trip.outboundJourney.journey.Legs)
      }
      if (!trip.inboundJourney?.journey.Legs) {
        Return = ''
      } else {
        Return = calculateJourneyDiscountStatus(trip.inboundJourney.journey.Legs)
      }

      return {
        Outward: Outward,
        Return: Return
      }
    })

    return {
      promoCodeMessage,
      resolveDiscountMessage,
      formatPrice,
      subtotal,
      groupAddons
    }
  }
})
