
import { computed, defineComponent, nextTick, PropType, ref, watch } from 'vue'
import useSwrv, { mutate } from 'swrv'
import useBasket from '@/composables/useBasket'
import { getJourneys } from '@/expressway-api/journeys.api'
import BasketFooter from '@/components/BasketFooter.vue'
import JourneyFeed from '@/components/JourneyFeed.vue'
import PageHeader from '@/components/PageHeader.vue'
import GenericError from '@/components/GenericError.vue'
import Spinner from '@/components/Spinner.vue'
import { PriceInfo } from '@/models/Journey'
import router from '@/router'
import useTrip from '@/composables/useTrip'
import { journeySubheading } from '@/helpers'
import useSnackbar from '@/composables/useSnackbar'
import { Fares, urlEncodeFares } from '@/helpers/fares'
import dayjs from 'dayjs'
import { sendExponeaCheckoutEvent } from '@/helpers/exponeaHelper'

export default defineComponent({
  name: 'SelectJourney',
  props: {
    departureDate: {
      type: String,
      required: true
    },
    oneWay: Boolean,
    returnDate: String,
    originId: {
      type: Number,
      required: true
    },
    destinationId: {
      type: Number,
      required: true
    },
    isOutbound: {
      type: Boolean,
      default: true
    },
    fares: {
      type: Object as PropType<Fares>,
      required: true
    },
    wheelchairPassengerFareClass: {
      type: String,
      required: false,
      default: ''
    },
    wheelchair: {
      type: Boolean,
      required: false,
      default: false
    },
    promoCode: String
  },
  components: { BasketFooter, JourneyFeed, GenericError, Spinner, PageHeader },
  // eslint-disable-next-line max-lines-per-function
  setup (props) {
    const { setSnackbar } = useSnackbar()
    const onBasketError = (error: Error) => { setSnackbar(error.message) }
    const {
      confirmBasket,
      isSetting: settingBasket,
      basketId: currentBasketId
    } = useBasket(onBasketError)
    const activeIndex = ref()
    const selectedPrice = ref<PriceInfo>()
    const { trip, updateTripJourney, clearTrip, pricingPassengers } = useTrip()
    if (props.isOutbound) clearTrip()

    const returnParams = {
      ...props,
      isOutbound: false
    }
    const { data: journeys, error } = useSwrv(
      () => `/journeys/search/${JSON.stringify(props)}`,
      () => getJourneys(props)
    )

    const selectedJourney = computed(() => journeys.value?.[activeIndex.value])
    const subheading = computed(
      () => selectedJourney.value && journeySubheading(selectedJourney.value)
    )

    watch(journeys, journeys => {
      activeIndex.value = journeys?.findIndex(j => j.IsForSale)
      nextTick(() => {
        const [activeJourneyCard] = document.getElementsByClassName('active journey card')
        activeJourneyCard?.scrollIntoView({ behavior: 'smooth', block: 'end' })
        document.getElementById('header')?.focus()
      })
    })

    watch(selectedJourney, journey => {
      if (!journey) return

      const newPrice = journey.PriceInfo.find(pi =>
        pi.PriceClassName === selectedPrice.value?.PriceClassName)
      selectedPrice.value = newPrice ?? journey.PriceInfo[0]
    })

    mutate(
      `/journeys/search/${JSON.stringify(returnParams)}`,
      getJourneys(returnParams)
    )

    watch(props, () => { activeIndex.value = 0 })

    watch([selectedJourney, selectedPrice], () => {
      if (!selectedJourney.value || !selectedPrice.value) return

      updateTripJourney(props.isOutbound, {
        journey: selectedJourney.value,
        priceInfo: selectedPrice.value
      })
    })
    const pushExponeaEvent = () => {
      sendExponeaCheckoutEvent({
        step: 'selected-journey',
        basket_code: currentBasketId.value,
        return_journey: !props.isOutbound,
        departure_date: dayjs(selectedJourney.value?.DepartureDateTime).format('YYYY-MM-DD'),
        departure_time: dayjs(selectedJourney.value?.DepartureDateTime).format('HH:mm:ss'),
        arrival_date: dayjs(selectedJourney.value?.ArrivalDateTime).format('YYYY-MM-DD'),
        arrival_time: dayjs(selectedJourney.value?.ArrivalDateTime).format('HH:mm:ss'),
        seat_class: selectedPrice.value?.PriceClassName,
        price: selectedPrice.value?.Price
      })
    }
    const nextStep = () => {
      if (props.oneWay || !props.isOutbound) {
        confirmBasket(trip)
        pushExponeaEvent()
      } else {
        router.push({
          name: 'Select Journey',
          query: {
            ...props,
            oneWay: 'false',
            isOutbound: 'false',
            fares: urlEncodeFares(props.fares),
            wheelchair: props.wheelchair.toString()
          }
        })
      }
    }
    const noJourneyFound = computed(() => {
      if (!journeys || journeys.value === undefined) return true

      return !(journeys.value.length > 0)
    })

    return {
      noJourneyFound,
      journeys,
      error,
      settingBasket,
      subheading,
      activeIndex,
      selectedJourney,
      selectedPrice,
      nextStep,
      currentBasketId,
      pricingPassengers
    }
  }
})
