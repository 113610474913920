
import { defineComponent, computed, inject, ComputedRef } from 'vue'
import Modal from '@/components/Modal.vue'
import Spinner from '@/components/Spinner.vue'
import PassengerList from '@/components/PassengerList.vue'
import { PassengerGroup } from '@/models/Passenger'
import { formatPrice } from '@/helpers'

export default defineComponent({
  components: {
    Modal,
    Spinner,
    PassengerList
  },
  emits: ['close'],
  props: {
    showModal: {
      type: Boolean,
      required: true
    },
    returnNeeded: {
      type: Boolean,
      default: false
    },
    promoCode: {
      type: String
    }
  },
  setup (props) {
    const amendPriceVariation = inject<number | null>('amendPriceVariation', null)
    const passengers = inject<ComputedRef<PassengerGroup[]>>('pricingPassengers')
    const amendFee = inject<number | null>('amendFee', null)

    const needsReturn = computed((): boolean =>
      props.returnNeeded &&
      !(passengers?.value?.some(passengerGroup =>
        passengerGroup.groupType.toLowerCase() === 'return' &&
        passengerGroup.passengers.length > 0
      ))
    )
    return {
      isAmend: amendPriceVariation !== null,
      amendPriceVariation,
      passengers,
      total: inject('totalPrice'),
      formatPrice,
      needsReturn,
      amendFee
    }
  }
})
