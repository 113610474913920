
import { defineComponent, PropType, computed } from 'vue'
import JourneyCard from '@/components/JourneyCard.vue'
import { Journey, PriceInfo } from '@/models/Journey'
import AlertIconAlt from '@/components/vectors/AlertIconAlt.vue'
import CheckLineIcon from '@/components/vectors/CheckLineIcon.vue'
import { PromoCodeValidation } from 'src/models/Journey'
export default defineComponent({
  props: {
    journeys: {
      type: Array as PropType<Journey[]>,
      required: true
    },
    activeIndex: Number,
    selectedPrice: {
      type: Object as PropType<PriceInfo>,
      required: true
    },
    promoCode: {
      type: String,
      default: ''
    }
  },
  components: { JourneyCard, AlertIconAlt, CheckLineIcon },
  emits: ['update:activeIndex', 'update:selectedPrice'],
  setup (_props, { emit }) {
    const select = (index: number) => {
      emit('update:activeIndex', index)
    }

    const appliedPromoCode = computed(() => {
      const codesApplied = new Map()
      _props.journeys.forEach(journey =>
        journey.Legs.forEach(leg => {
          if (leg.PromoCodeValidationResult?.Code === 'ok') {
            codesApplied.set(
              leg.PromoCodeValidationResult.Description,
              leg.PromoCodeValidationResult
            )
          }
        }))
      const values = [] as PromoCodeValidation[]
      codesApplied.forEach(element => {
        values.push(element)
      })
      if (values.length === 0) return ''

      return values.length === 1
        ? `${values[0].Description} Discount Applied`
        : 'Multiple Discounts Applied'
    })

    const invalidPromoCode = computed(() => _props.journeys.every(journey => {
      if (!_props.promoCode || _props.promoCode === '') return false
      return !journey.Legs.some(leg => leg.PromoCodeValidationResult?.Code === 'ok')
    }
    ))
    const validPromoCode = computed(() => _props.journeys.some(journey =>
      journey.Legs.some(leg => leg.PromoCodeValidationResult?.Code === 'ok')
    ))

    return { appliedPromoCode, validPromoCode, invalidPromoCode, select }
  }
})
