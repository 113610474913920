
import { computed, defineComponent, reactive, watch } from 'vue'
import DatePicker from '@/components/DatePicker/DatePicker.vue'
import Checkbox from '@/components/Checkbox.vue'
import dayjs from 'dayjs'
import useTabs from '@/composables/useTabs'

interface TabData {
  tab?: HTMLButtonElement;
  min?: string;
  max?: string;
  value?: string;
}

export default defineComponent({
  components: { DatePicker, Checkbox },
  emits: ['update:oneWay', 'update:outward', 'update:return'],
  props: {
    oneWay: Boolean,
    outward: String,
    return: String,
    max: {
      type: String,
      validator: (value: string) => dayjs(value).isBefore(dayjs().add(90, 'days'))
    },
    min: {
      type: String,
      validator: (value: string) => !dayjs(value).isBefore(dayjs())
    }
  },
  setup (props) {
    const today = dayjs()
    const limit = dayjs().add(90, 'day')

    const minDate = computed((): string => {
      const minProp = dayjs(props.min)
      return (minProp < today ? today : minProp).format('YYYY-MM-DD')
    })

    const maxDate = computed((): string => {
      const maxProp = dayjs(props.max || limit)
      return (maxProp > limit ? limit : maxProp).format('YYYY-MM-DD')
    })

    const legs = reactive<{ [x: string]: TabData }>({
      outward: {
        value: props.outward,
        min: minDate.value,
        max: maxDate.value
      },
      return: {
        value: props.return,
        min: minDate.value,
        max: maxDate.value
      }
    })
    const tabNames = computed(() => Object.keys(legs))
    const { activeTab, nextTab, previousTab } = computed(() =>
      useTabs(tabNames.value)
    ).value

    const filteredLegs = computed(() => props.oneWay ? { outward: legs.outward } : legs)

    watch(activeTab, newValue => {
      legs[newValue].tab?.focus()
    })

    watch(
      () => legs.return?.value,
      value => {
        legs.outward.max = value || maxDate.value
      }
    )

    watch(
      () => legs.outward.value,
      value => {
        if (!legs.return) return
        legs.return.min = value || minDate.value
      }
    )

    return {
      activeTab,
      nextTab,
      previousTab,
      filteredLegs,
      formatDate: (date: string) => dayjs(date).format('DD/MM/YYYY'),
      localOneWay: props.oneWay
    }
  }
})
