
import { computed, defineComponent, onMounted, onUnmounted, reactive, watch, ref } from 'vue'
import WheelchairIcon from '@/components/vectors/Wheelchair.vue'
import ModalLink from '@/components/CMSModalLink.vue'
import Arrow from '@/components/vectors/ArrowForward.vue'
import InfoIcon from '@/components/vectors/Info.vue'
import TicketTypeQuantitySelect from '@/components/TicketTypeQuantitySelect.vue'
import Checkbox from '@/components/Checkbox.vue'
import { passengerTypes } from '@/models/FareClass'
import ButtonConfirmation from '@/components/ButtonConfirmation.vue'

export default defineComponent({
  components: {
    ButtonConfirmation,
    WheelchairIcon,
    InfoIcon,
    TicketTypeQuantitySelect,
    ModalLink,
    Arrow,
    Checkbox
  },
  emits: ['update:modelValue', 'update:expanded', 'update:wheelchairPassenger'],
  props: {
    modelValue: {
      type: Object,
      required: true
    },
    wheelchairPassenger: {
      type: Boolean,
      required: true
    },
    expanded: {
      type: Boolean,
      default: false
    }
  },
  setup (props, { emit }) {
    const fares = reactive(props.modelValue)
    const totalPassengers = computed(() =>
      Object.values(fares).reduce((sum, addend) => sum + addend, 0)
    )
    const showWheelchairMenu = ref(props.wheelchairPassenger)

    const wheelchairPassengerInternal = ref(props.wheelchairPassenger)

    watch(wheelchairPassengerInternal, () => {
      emit('update:wheelchairPassenger', wheelchairPassengerInternal.value)
    })

    watch(fares, () => { emit('update:modelValue', fares) })

    const onClickOutside = ({ target }: Event) => {
      if (
        target instanceof Element &&
        target.closest('.journey-passenger-select')
      ) return

      emit('update:expanded', false)
    }

    onMounted(() => document.addEventListener('click', onClickOutside))
    onUnmounted(() => document.removeEventListener('click', onClickOutside))

    return {
      showWheelchairMenu,
      passengerTypes,
      totalPassengers,
      fares,
      wheelchairPassengerInternal
    }
  }
})
